import { useState, useEffect, useRef, useCallback } from 'react';
import gameService from '../../../services/game.service';

const useVideoPlayer = (videoElement, game_id) => {
    const [labeldata, setLabeldata] = useState({});
    const [videoCufr, setVideoCufr] = useState(0);
    const retreiving = useRef(false);

    const detectdata = useRef({});
    const currentDetectFr = useRef(-10000);
    const frameTobackward = useRef(1800);
    const frameToforward = useRef(5400);
    const totalTobackword = useRef(1800);
    const previousSec = useRef(-1);
    const previousDeFr = useRef(-1);

    const retreivedata = useCallback(
        async (strHr, strMin, strsec, minTobackward, minToforward, detectFr, isMerge) => {
            let videoTime = `${strHr}:${strMin}:${strsec}`;
            await gameService.getPlayersDetection(game_id, videoTime, minTobackward, minToforward).then((data) => {
                if (data.length === 0) {
                    retreiving.current = false;
                    return;
                }

                const groupByCategory = data.reduce((group, product) => {
                    const { st } = product;
                    group[st] = group[st] ?? [];
                    group[st].push(product);
                    return group;
                }, {});

                var newCategory = {};
                for (var key in groupByCategory) {
                    var timearray = key.split(':');
                    var secs = parseFloat(parseFloat(timearray[2])?.toFixed(1));
                    var hours = parseInt(timearray[0]);
                    var mins = parseInt(timearray[1]);
                    var frames = Math.round((mins * 60 + hours * 3600 + secs) * 30);
                    newCategory[frames] = groupByCategory[key];
                }

                if (isMerge) {
                    const asArray = Object.entries(detectdata.current);
                    const filtered = asArray.filter(([key, value]) => key > detectFr - 2700); // store 5 minutes data backward
                    const backToObject = Object.fromEntries(filtered);

                    totalTobackword.current = detectFr - Math.min(...Object.keys(backToObject));
                    detectdata.current = {
                        ...backToObject,
                        ...newCategory
                    };
                } else {
                    detectdata.current = newCategory;
                }
                setLabeldata(detectdata.current);
                currentDetectFr.current = detectFr;
                retreiving.current = false;
            });
        },
        [game_id]
    );

    const detect = useCallback(async () => {
        let currentsec = videoElement.current.getCurrentTime();
        let currentframe = parseInt(parseFloat(currentsec?.toFixed(1)) * 30);
        setVideoCufr(currentframe);

        if (previousDeFr.current === currentDetectFr.current && previousSec.current === currentsec) return;
        previousDeFr.current = currentDetectFr.current;
        previousSec.current = currentsec;
        if (currentframe > currentDetectFr.current - totalTobackword.current && currentframe < currentDetectFr.current + frameToforward.current) {
            if (currentframe < currentDetectFr.current + frameToforward.current && currentframe >= currentDetectFr.current + frameToforward.current - 900) {
                if (!retreiving.current) {
                    let totalSec = Math.floor((currentDetectFr.current + frameToforward.current) / 30);
                    let hr = Math.floor(totalSec / 3600);
                    let min = Math.floor((totalSec - hr * 3600) / 60);
                    let sec = totalSec - hr * 3600 - min * 60;
                    let strHr = hr < 10 ? '0' + hr.toString() : hr.toString();
                    let strMin = min < 10 ? '0' + min.toString() : min.toString();
                    let strsec = sec < 10 ? '0' + sec.toString() : sec.toString();
                    let minToforward = frameToforward.current / 1800;
                    retreiving.current = true;

                    retreivedata(strHr, strMin, strsec, minToforward, totalSec * 30, true);
                }
            }
        }

        if (currentframe <= currentDetectFr.current - frameTobackward.current || currentframe >= currentDetectFr.current + frameToforward.current) {
            if (!retreiving.current) {
                let totalSec = Math.floor(currentsec);
                let hr = Math.floor(totalSec / 3600);
                let min = Math.floor((totalSec - hr * 3600) / 60);
                let sec = totalSec - hr * 3600 - min * 60;
                let strHr = hr < 10 ? '0' + hr.toString() : hr.toString();
                let strMin = min < 10 ? '0' + min.toString() : min.toString();
                let strsec = sec < 10 ? '0' + sec.toString() : sec.toString();
                let minTobackward = frameTobackward.current / 1800;
                let minToforward = frameToforward.current / 1800;
                retreiving.current = true;

                retreivedata(strHr, strMin, strsec, minTobackward, minToforward, totalSec * 30, false);
            }
        }
    }, [videoElement, retreivedata, previousDeFr, previousSec, currentDetectFr, frameTobackward, frameToforward, totalTobackword]);

    useEffect(() => {
        let timeInt = setInterval(() => {
            detect();
        }, 30);
        return () => clearInterval(timeInt);
    }, [detect]);

    return {
        labeldata,
        videoCufr
    };
};

export default useVideoPlayer;
