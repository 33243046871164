export const APIBASEURL = window.location.host.startsWith('localhost') ? 'http://localhost:3000' : 'https://soccerapi.scouting4u.com';
export const SECRET = 'AUTH_SECRET';
// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const closedDrawerWidth = 113;
export const appDrawerWidth = 320;

// export const xmlServer = "http://84.247.180.129"
export const xmlServer = 'https://render.scouting4u.com';
//export const xmlServer = "https://render.S4UPro.com"
export const contabo_link = 'https://eu2.contabostorage.com/e6025e82372e484a80616b94eca3ca5a:soccer/videos/';
