import React, { useState, useEffect } from 'react';
import { Box, Select, MenuItem, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cookie from 'react-cookies';
import i18next from 'i18next';
import MenuIcon from '@mui/icons-material/MenuOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import GameService from '../../../services/game.service';
import MenuItems from './menuItem';
import { menuData } from './menuData';
import { getCorrectionCount } from '../../../actions/game';
import { getLists, getPlayersStatsAdvanceSummaryList, getGoalkeepersStatsAdvanceSummaryList } from '../../../actions/coach';
import { getTeamIds, getLeagueIds, getGameIds } from '../../../helpers/coach';
import { MenuProps } from '../components/common';
import SeasonContext from '../../../common/Context';
import LogoAlone from '../../../assets/logoAlone.png';
import '../coach_style.css';
const Sidebar = ({ t }) => {
    const { seasonFilter, setSeasonFilter } = React.useContext(SeasonContext);
    const [minimum, setMinimum] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(undefined);
    const [selectIndex, setSelectIndex] = useState(0);
    const [pathname, setPathname] = useState(window.location.pathname);
    const [gameCount, setGameCount] = useState(0);
    const [, setMenuList] = useState([]);
    const [, setLoading] = useState(false);
    const [menuState, setMenuState] = useState({ corrections: false });
    const savedLanguage = cookie.load('lang') ? cookie.load('lang') : 'en';
    const [language] = useState(savedLanguage);
    const currentGame = useSelector((state) => state.game);
    const teamsList = useSelector((state) => state.coach.teamsList);
    const seasonList = useSelector((state) => state.coach.seasonList);
    const leagueList = useSelector((state) => state.coach.leagueList);
    const gamesList = useSelector((state) => state.coach.gamesList);
    const playersStatsAdvanceSummaryList = useSelector((state) => state.coach.playersStatsAdvanceSummaryList);
    const goalkeepersStatsAdvanceSummaryList = useSelector((state) => state.coach.goalkeepersStatsAdvanceSummaryList);
    const menuDataItem = [];
    menuData.forEach((menuGroup) => {
        menuGroup.forEach((item) => {
            menuDataItem.push(item);
        });
    });
    const dispatch = useDispatch();
    const handleMenuControl = () => {
        if (minimum) setMinimum(false);
        else setMinimum(true);
    };
    const handleMouseEnter = (index) => {
        setHoverIndex(index);
    };
    const handleMouseLeave = () => {
        setHoverIndex(undefined);
    };
    useEffect(() => {
        cookie.save('lang', language, { path: '/' });
        i18next.changeLanguage(language);
        if (language === 'iw' || language === 'ar') {
            document.body.style.direction = 'rtl';
        } else {
            document.body.style.direction = 'ltr';
        }
    }, [language]);
    useEffect(() => {
        (async () => {
            setPathname(window.location.pathname);
            setMenuList(menuDataItem.filter((item) => item.id !== 'dashboard'));
            const paths = menuDataItem.filter((item) => item.id !== 'dashboard').map((item) => item.path);
            if (pathname.includes('/new_coach/dashboard')) setSelectIndex(paths.indexOf('/new_coach/dashboard'));
            else if (pathname.includes('/new_coach/games')) setSelectIndex(paths.indexOf('/new_coach/games'));
            else if (pathname.includes('/new_coach/reports')) setSelectIndex(paths.indexOf('/new_coach/reports'));
            else if (pathname.includes('/new_coach/edits')) setSelectIndex(paths.indexOf('/new_coach/edits'));
            else if (pathname.includes('/new_coach/teams')) setSelectIndex(paths.indexOf('/new_coach/teams'));
            // else if (pathname.includes("/new_coach/opponents")) setSelectIndex(paths.indexOf("/new_coach/opponents"));
            else if (pathname.includes('/new_coach/reports')) setSelectIndex(paths.indexOf('/new_coach/reports'));
            else if (pathname.includes('/new_coach/leaders')) setSelectIndex(paths.indexOf('/new_coach/leaders'));
            else if (pathname.includes('/new_coach/players')) setSelectIndex(paths.indexOf('/new_coach/players'));
            else if (pathname.includes('/new_coach/goalkeepers')) setSelectIndex(paths.indexOf('/new_coach/goalkeepers'));
            else if (pathname.includes('/new_coach/settings')) setSelectIndex(paths.indexOf('/new_coach/settings'));
            else if (pathname.includes('/new_coach/video_cutter')) setSelectIndex(paths.indexOf('/new_coach/video_cutter'));
            else if (pathname.includes('/new_coach/renders')) setSelectIndex(paths.indexOf('/new_coach/renders'));
            else setSelectIndex(paths.indexOf('/new_coach/corrections'));
            await GameService.getNumberOfGamesOrdered(null).then((res) => {
                setGameCount(res[0].total_game);
                setMenuList(menuDataItem.filter((item) => item.id !== 'dashboard'));
            });
            dispatch(getCorrectionCount());
        })();
    }, [pathname]);
    useEffect(() => {
        setMenuState({ ...menuState, corrections: currentGame.correctionCnt > 0 });
    }, [currentGame]);
    useEffect(() => {
        if (seasonList.length) {
            setSeasonFilter(seasonList[0]);
        }
    }, [seasonList]);
    useEffect(() => {
        setLoading(true);
    }, [seasonFilter]);
    useEffect(() => {
        setLoading(false);
    }, [gamesList]);
    useEffect(() => {
        if (!teamsList.length && !seasonList.length && !leagueList.length) {
            dispatch(getLists());
        }
        let leagueIds = getLeagueIds(leagueList);
        let teamIds = getTeamIds(teamsList, seasonFilter.id);
        let gameIds = getGameIds(gamesList, seasonFilter.id);
        if (playersStatsAdvanceSummaryList !== undefined) {
            if (!playersStatsAdvanceSummaryList.hasOwnProperty(seasonFilter === 'none' ? 'all' : seasonFilter.name)) {
                if (gamesList.length) dispatch(getPlayersStatsAdvanceSummaryList(seasonFilter, leagueIds, teamIds, gameIds));
            }
        }
        if (goalkeepersStatsAdvanceSummaryList !== undefined) {
            if (!goalkeepersStatsAdvanceSummaryList.hasOwnProperty(seasonFilter === 'none' ? 'all' : seasonFilter.name)) {
                if (gamesList.length) dispatch(getGoalkeepersStatsAdvanceSummaryList(seasonFilter, leagueIds, teamIds, gameIds));
            }
        }
    }, [seasonFilter, gamesList, playersStatsAdvanceSummaryList, goalkeepersStatsAdvanceSummaryList]);
    return (
        <Box sx={{ borderRight: '0px solid #17375E', backgroundColor: '#ffffff', width: minimum ? '80px' : '180px', paddingTop: '32px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: minimum ? 'none' : 'space-between', 'svg path': { fill: 'black' }, padding: '0 24px 0 30px', height: '40px' }}>
                    <MenuIcon sx={{ cursor: 'pointer' }} onClick={() => handleMenuControl()} />
                    {!minimum && (
                        <Link to="/" style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={LogoAlone} style={{ height: '40px' }} />
                        </Link>
                    )}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {!minimum && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px' }}>
                            <p className="select-narrator">{t('Season')}</p>
                            <Select
                                value={seasonFilter}
                                onChange={(event) => setSeasonFilter(event.target.value)}
                                label=""
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                sx={{ outline: 'none', height: '36px', width: '120px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            >
                                {seasonList.map((season, index) => (
                                    <MenuItem key={index + 1} value={season}>
                                        {season.name}
                                    </MenuItem>
                                ))}
                                {/* <MenuItem key={0} value={"none"}>
                                    {t("All")}
                                </MenuItem> */}
                            </Select>
                        </Box>
                    )}
                    {menuData.map((menuGroup, index) => (
                        <Box key={index}>
                            <Divider sx={{ opacity: '0.6',  backgroundColor: '#888888', marginTop: index === 0 ? '12px' : '0px', marginBottom: '10px' }} />
                            {menuGroup.map((menuItem) => (
                                <Box key={menuItem.key} onMouseEnter={() => handleMouseEnter(menuItem.key)} onMouseLeave={handleMouseLeave} onClick={() => setSelectIndex(menuItem.key)}>
                                    <MenuItems
                                        path={menuItem.path}
                                        icon={menuItem.icon}
                                        title={t(menuItem.title)}
                                        isMinimized={minimum}
                                        isHover={menuItem.key === hoverIndex ? true : false}
                                        isSelected={menuItem.key === selectIndex ? true : false}
                                        isEnabled={menuState[menuItem.id]}
                                    />
                                </Box>
                            ))}
                        </Box>
                    ))}
                </Box>
            </Box>
            {minimum ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', gap: '4px', marginBottom: '8px' }}>
                    <p className="sidebar-game-count">{gameCount}</p>
                    <p className="sidebar-game-count">Games</p>
                    <p className="sidebar-game-count">Ordered</p>
                </div>
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '5vh', marginBottom: '8px' }}>
                    <p className="sidebar-game-count">
                        {gameCount} {t('Games Ordered')}
                    </p>
                </Box>
            )}
        </Box>
    );
};
export default Sidebar;
