import { combineReducers } from 'redux';
import auth from './auth';
import message from './message';
import resetpwd from './resetpwd';
import game from './game';
import settingReducer from './setting.reducer';
import coachReducer from '../helpers/coachSlice';

export default combineReducers({
    auth,
    message,
    resetpwd,
    game,
    setting: settingReducer,
    coach: coachReducer
});
